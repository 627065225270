<template>
  <calculator-detail title="Financial Checkup">
    <div slot="calculator" class="mt-4">
      <b-row>
        <b-col sm="1">
          <label for="input-small">Status</label>
        </b-col>
        <b-col sm="4">
          <b-form-select v-model="status" :options="options">
            <template #first>
              <b-form-select-option value="" disabled
                >-- Pilih status --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col sm="6">
          <h5>ASET</h5>
          <b-table
            small
            bordered
            :fields="aset_likuid_fields"
            :items="aset_likuid_items"
            foot-clone
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
                }"
              />
            </template>
            <template #cell(nilai_saat_ini)="row">
              <b-form-input
                v-model="row.item.nilai_saat_ini"
                placeholder="0"
                class="text-right"
                @keyup="sum('aset_likuid')"
                v-currency
              ></b-form-input>
            </template>
            <template #foot(aset_likuid)> Total </template>
            <template #foot(nilai_saat_ini)>
              <b-form-input
                v-model="total_aset_likuid"
                placeholder="0"
                class="text-right"
                disabled
              ></b-form-input>
            </template>
          </b-table>
          <b-table
            small
            bordered
            :fields="aset_investasi_fields"
            :items="aset_investasi_items"
            foot-clone
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
                }"
              />
            </template>
            <template #cell(nilai_saat_ini)="row">
              <b-form-input
                v-model="row.item.nilai_saat_ini"
                placeholder="0"
                class="text-right"
                @keyup="sum('aset_investasi')"
                v-currency
              ></b-form-input>
            </template>
            <template #foot(aset_investasi)> Total </template>
            <template #foot(nilai_saat_ini)>
              <b-form-input
                v-model="total_aset_investasi"
                placeholder="0"
                class="text-right"
                disabled
              ></b-form-input> </template
          ></b-table>
          <b-table
            small
            bordered
            :fields="aset_pribadi_fields"
            :items="aset_pribadi_items"
            foot-clone
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
                }"
              />
            </template>
            <template #cell(nilai_saat_ini)="row">
              <b-form-input
                v-model="row.item.nilai_saat_ini"
                placeholder="0"
                class="text-right"
                @keyup="sum('aset_pribadi')"
                v-currency
              ></b-form-input>
            </template>
            <template #foot(aset_pribadi)> Total </template>
            <template #foot(nilai_saat_ini)>
              <b-form-input
                v-model="total_aset_pribadi"
                placeholder="0"
                class="text-right"
                disabled
              ></b-form-input>
            </template>
          </b-table>
          <b-table-simple small bordered>
            <b-tr>
              <b-th style="width: 180px">Total Aset</b-th>
              <b-th style="width: 200px">
                <b-form-input
                  v-model="total_aset"
                  value="0"
                  class="text-right"
                  disabled
                ></b-form-input>
              </b-th>
            </b-tr>
          </b-table-simple>
        </b-col>
        <b-col sm="6">
          <h5>UTANG</h5>
          <b-table
            small
            bordered
            :fields="utang_jangka_pendek_fields"
            :items="utang_jangka_pendek_items"
            foot-clone
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
                }"
              />
            </template>
            <template #cell(nilai_saat_ini)="row">
              <b-form-input
                v-model="row.item.nilai_saat_ini"
                placeholder="0"
                class="text-right"
                @keyup="sum('utang_jangka_pendek')"
                v-currency
              ></b-form-input>
            </template>
            <template #foot(utang_jangka_pendek)> Total </template>
            <template #foot(nilai_saat_ini)>
              <b-form-input
                v-model="total_utang_jangka_pendek"
                placeholder="0"
                class="text-right"
                disabled
              ></b-form-input>
            </template>
          </b-table>
          <b-table
            small
            bordered
            :fields="utang_jangka_panjang_fields"
            :items="utang_jangka_panjang_items"
            foot-clone
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
                }"
              />
            </template>
            <template #cell(nilai_saat_ini)="row">
              <b-form-input
                v-model="row.item.nilai_saat_ini"
                placeholder="0"
                class="text-right"
                @keyup="sum('utang_jangka_panjang')"
                v-currency
              ></b-form-input>
            </template>
            <template #foot(utang_jangka_panjang)> Total </template>
            <template #foot(nilai_saat_ini)>
              <b-form-input
                v-model="total_utang_jangka_panjang"
                placeholder="0"
                class="text-right"
                disabled
              ></b-form-input>
            </template>
          </b-table>
          <b-table-simple small bordered>
            <b-tr>
              <b-th style="width: 180px">Total Utang</b-th>
              <b-th style="width: 200px">
                <b-form-input
                  v-model="total_utang"
                  value="0"
                  class="text-right"
                  disabled
                ></b-form-input>
              </b-th>
            </b-tr>
          </b-table-simple>
          <table class="table table-bordered table-sm">
            <tr>
              <th style="width: 180px">Kekayaan Bersih</th>
              <th style="width: 200px">
                <b-form-input
                  :value="kekayaanBersih"
                  class="text-right"
                  disabled
                ></b-form-input>
              </th>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mt-5">
        <b-col sm="6">
          <h5>Pendapatan Bulanan</h5>
          <b-table
            small
            bordered
            :items="pendapatan_bulanan_items"
            thead-class="d-none"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
                }"
              />
            </template>
            <template #cell(nilai_saat_ini)="row">
              <template v-if="row.index == 4">
                <b-form-input
                  v-model="total_pendapatan_bulanan"
                  value="0"
                  class="text-right"
                  disabled
                ></b-form-input>
              </template>
              <template v-else-if="row.index == 5">
                <b-form-input
                  :value="totalPendapatanTahunan"
                  class="text-right"
                  disabled
                ></b-form-input>
              </template>

              <template v-else>
                <b-form-input
                  v-model="row.item.nilai_saat_ini"
                  placeholder="0"
                  class="text-right"
                  @keyup="sum('pendapatan_bulanan')"
                  v-currency
                ></b-form-input>
              </template>
            </template>
          </b-table>
        </b-col>
        <b-col sm="6">
          <h5>Pengeluaran Bulanan</h5>
          <b-table
            responsive
            small
            bordered
            :items="pengeluaran_bulanan_items"
            foot-clone
            thead-class="d-none"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
                }"
              />
            </template>
            <template #cell(nilai_saat_ini)="row">
              <template v-if="row.item.keterangan == 'Investasi rutin'">
                <b-form-input
                  v-model="row.item.nilai_saat_ini"
                  placeholder="0"
                  class="text-right investasi_rutin"
                  @keyup="
                    sum('pengeluaran_bulanan')
                    calculateTabungan()
                  "
                  v-currency
                ></b-form-input>
              </template>
              <template v-else-if="row.item.keterangan == 'Cicilan utang'">
                <b-form-input
                  v-model="row.item.nilai_saat_ini"
                  placeholder="0"
                  class="text-right cicilan_utang"
                  @keyup="
                    sum('pengeluaran_bulanan')
                    calculateCicilanUtang()
                  "
                  v-currency
                ></b-form-input>
              </template>
              <template v-else>
                <b-form-input
                  v-model="row.item.nilai_saat_ini"
                  placeholder="0"
                  class="text-right"
                  @keyup="sum('pengeluaran_bulanan')"
                  v-currency
                ></b-form-input>
              </template>
            </template>
            <template #foot(keterangan_utama)>&nbsp;</template>
            <template #foot(keterangan)>Total</template>
            <template #foot(nilai_saat_ini)>
              <b-form-input
                v-model="total_pengeluaran_bulanan"
                value="0"
                class="text-right"
                disabled
              ></b-form-input>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5>Rasio Keuangan</h5>
          <b-table-simple small bordered>
            <b-thead>
              <b-tr>
                <b-th colspan="2"></b-th>
                <b-th>Ideal</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td style="width: 40%">Dana Darurat</b-td>
                <b-td style="width: 20%" class="text-right">
                  {{ `${danaDarurat}x` }}
                </b-td>
                <b-td>{{ danaDaruratIdeal }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Tabungan</b-td>
                <b-td class="text-right">{{ `${this.tabungan}%` }}</b-td>
                <b-td>{{ tabunganIdeal }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Cicilan utang</b-td>
                <b-td class="text-right">{{ `${this.cicilanUtang}%` }}</b-td>
                <b-td>{{ cicilanUtangIdeal }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </calculator-detail>
</template>

<script>
import CalculatorDetail from '../../components/CalculatorDetail.vue'
export default {
  name: 'FinancialCheckup',
  components: {
    CalculatorDetail,
  },
  data() {
    return {
      investasiRutingId: 'investasi_rutin',
      cicilanUtangId: 'cicilan_utang',
      status: '',
      total_aset_likuid: 0,
      total_aset_investasi: 0,
      total_aset_pribadi: 0,
      total_utang_jangka_pendek: 0,
      total_utang_jangka_panjang: 0,
      total_pendapatan_bulanan: 0,
      total_pendapatan_tahunan: 0,
      total_pengeluaran_bulanan: 0,
      total_aset: 0,
      total_utang: 0,
      tabungan: 0,
      cicilanUtang: 0,
      options: [
        { value: '3 s.d 6 x', text: 'Single' },
        { value: '9 s.d 12x', text: 'Menikah' },
      ],
      aset_likuid_fields: [
        'aset_likuid',
        {
          key: 'nilai_saat_ini',
          label: 'Nilai saat ini (dalam Rp)',
        },
      ],
      aset_likuid_items: [
        { aset_likuid: 'Kas dan setara kas', nilai_saat_ini: '' },
        { aset_likuid: 'Tabungan', nilai_saat_ini: '' },
        { aset_likuid: 'Deposito', nilai_saat_ini: '' },
        { aset_likuid: 'Lain-lain', nilai_saat_ini: '' },
      ],
      aset_investasi_fields: [
        'aset_investasi',
        {
          key: 'nilai_saat_ini',
          label: 'Nilai saat ini (dalam Rp)',
        },
      ],
      aset_investasi_items: [
        { aset_investasi: 'Saham', nilai_saat_ini: '' },
        { aset_investasi: 'Obligasi', nilai_saat_ini: '' },
        {
          aset_investasi: 'Lahan dan Properti (yang disewakan)',
          nilai_saat_ini: '',
        },
        { aset_investasi: 'Reksadana', nilai_saat_ini: '' },
        { aset_investasi: 'Lain-lain', nilai_saat_ini: '' },
      ],
      aset_pribadi_fields: [
        'aset_pribadi',
        {
          key: 'nilai_saat_ini',
          label: 'Nilai saat ini (dalam Rp)',
        },
      ],
      aset_pribadi_items: [
        { aset_pribadi: 'Lahan dan Properti pribadi', nilai_saat_ini: '' },
        { aset_pribadi: 'Kendaraan pribadi', nilai_saat_ini: '' },
        { aset_pribadi: 'Koleksi pribadi', nilai_saat_ini: '' },
        { aset_pribadi: 'Lain-lain', nilai_saat_ini: '' },
      ],
      utang_jangka_pendek_fields: [
        'utang_jangka_pendek',
        {
          key: 'nilai_saat_ini',
          label: 'Sisa utang saat ini (dalam Rp)',
        },
      ],
      utang_jangka_pendek_items: [
        {
          utang_jangka_pendek: 'Kartu kredit',
          nilai_saat_ini: '',
        },
        { utang_jangka_pendek: 'Paylater', nilai_saat_ini: '' },
        { utang_jangka_pendek: 'KTA', nilai_saat_ini: '' },
        { utang_jangka_pendek: 'Lain-lain', nilai_saat_ini: '' },
      ],
      utang_jangka_panjang_fields: [
        'utang_jangka_panjang',
        {
          key: 'nilai_saat_ini',
          label: 'Sisa utang saat ini (dalam Rp)',
        },
      ],
      utang_jangka_panjang_items: [
        {
          utang_jangka_panjang: 'Kredit pemilikan rumah',
          nilai_saat_ini: '',
        },
        {
          utang_jangka_panjang: 'Kredit pemilikan apartemen',
          nilai_saat_ini: '',
        },
        {
          utang_jangka_panjang: 'Kredit pemilikan kendaraan',
          nilai_saat_ini: '',
        },
        { utang_jangka_panjang: 'Lain-lain', nilai_saat_ini: '' },
      ],
      pendapatan_bulanan_items: [
        { keterangan: 'Pendapatan tetap bulanan', nilai_saat_ini: 0 },
        { keterangan: 'Pendapatan investasi per bulan', nilai_saat_ini: 0 },
        { keterangan: 'Pendapatan dari sewa per bulan', nilai_saat_ini: 0 },
        { keterangan: 'Lain-lain', nilai_saat_ini: 0 },
        { keterangan: 'Total pendapatan bulanan', nilai_saat_ini: 0 },
        { keterangan: 'Pendapatan per tahun', nilai_saat_ini: 0 },
      ],
      pengeluaran_bulanan_items: [
        {
          keterangan_utama: 'Konsumsi',
          keterangan: 'Belanja rumah tangga',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Pengeluaran untuk anak',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Suplemen Kesehatan',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: 'Lunasi',
          keterangan: 'Sewa rumah',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Listrik, air, gas',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Cicilan utang',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Premi asuransi',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Asisten rumah tangga',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: 'Transportasi',
          keterangan: 'Bahan bakar',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Parkir dan tol',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Transportasi online/taksi',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: 'Investasi',
          keterangan: 'Investasi rutin',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: 'Donasi',
          keterangan: 'Zakat/sosial',
          nilai_saat_ini: 0,
        },
        { keterangan_utama: '', keterangan: 'Orang tua', nilai_saat_ini: 0 },
        {
          keterangan_utama: 'Privasi',
          keterangan: 'Hiburan',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Personal care',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: '',
          keterangan: 'Personal shopping',
          nilai_saat_ini: 0,
        },
        {
          keterangan_utama: 'Lain-lain',
          keterangan: 'Lain-lain',
          nilai_saat_ini: 0,
        },
      ],
    }
  },
  computed: {
    kekayaanBersih() {
      const result =
        this.unformatCurrency(this.total_aset) -
        this.unformatCurrency(this.total_utang)

      return this.formatToCurrency(result)
    },
    totalPendapatanTahunan() {
      return this.calculateRatio()
    },
    danaDarurat() {
      const result =
        this.unformatCurrency(this.total_aset_likuid) /
        this.unformatCurrency(this.total_pengeluaran_bulanan)

      return isNaN(result) ? 0 : result.toFixed(0)
    },
    danaDaruratIdeal() {
      return this.status
    },
    tabunganIdeal() {
      return 'min. 10%'
    },
    cicilanUtangIdeal() {
      return 'maks. 30%'
    },
  },
  methods: {
    calculateRatio() {
      this.total_pendapatan_tahunan =
        this.unformatCurrency(this.total_pendapatan_bulanan) * 12

      this.calculateTabungan()
      this.calculateCicilanUtang()

      return this.formatToCurrency(this.total_pendapatan_tahunan)
    },
    sum(type) {
      const result = this[`${type}_items`].reduce(
        (sum, item) =>
          sum + parseFloat(this.unformatCurrency(item.nilai_saat_ini) || 0),
        0
      )

      this.$set(this, `total_${type}`, this.formatToCurrency(result))

      this.calculateTotalAset()
      this.calculateTotalUtang()
    },
    calculateTotalAset() {
      const result =
        this.unformatCurrency(this.total_aset_likuid) +
        this.unformatCurrency(this.total_aset_investasi) +
        this.unformatCurrency(this.total_aset_pribadi)
      this.total_aset = this.formatToCurrency(result)
    },
    calculateTotalUtang() {
      const result =
        this.unformatCurrency(this.total_utang_jangka_pendek) +
        this.unformatCurrency(this.total_utang_jangka_panjang)

      this.total_utang = this.formatToCurrency(result)
    },
    calculateTabungan() {
      const tabunganInput = document.querySelector('.investasi_rutin')
      const tabungan = tabunganInput == null ? 0 : tabunganInput.value

      const result =
        ((this.unformatCurrency(tabungan) * 12) /
          this.unformatCurrency(this.total_pendapatan_tahunan)) *
        100

      this.tabungan = this.formatToCurrency(isNaN(result) ? 0 : result)
    },
    calculateCicilanUtang() {
      const cicilanUtangInput = document.querySelector('.cicilan_utang')
      const cicilanUtang =
        cicilanUtangInput == null ? 0 : cicilanUtangInput.value

      const result =
        ((this.unformatCurrency(cicilanUtang) * 12) /
          this.unformatCurrency(this.total_pendapatan_tahunan)) *
        100

      this.cicilanUtang = this.formatToCurrency(isNaN(result) ? 0 : result)
    },
  },
}
</script>
