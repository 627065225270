var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('calculator-detail',{attrs:{"title":"Financial Checkup"}},[_c('div',{staticClass:"mt-4",attrs:{"slot":"calculator"},slot:"calculator"},[_c('b-row',[_c('b-col',{attrs:{"sm":"1"}},[_c('label',{attrs:{"for":"input-small"}},[_vm._v("Status")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-select',{attrs:{"options":_vm.options},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("-- Pilih status --")])]},proxy:true}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"sm":"6"}},[_c('h5',[_vm._v("ASET")]),_c('b-table',{attrs:{"small":"","bordered":"","fields":_vm.aset_likuid_fields,"items":_vm.aset_likuid_items,"foot-clone":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
              })})})}},{key:"cell(nilai_saat_ini)",fn:function(row){return [_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right",attrs:{"placeholder":"0"},on:{"keyup":function($event){return _vm.sum('aset_likuid')}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]}},{key:"foot(aset_likuid)",fn:function(){return [_vm._v(" Total ")]},proxy:true},{key:"foot(nilai_saat_ini)",fn:function(){return [_c('b-form-input',{staticClass:"text-right",attrs:{"placeholder":"0","disabled":""},model:{value:(_vm.total_aset_likuid),callback:function ($$v) {_vm.total_aset_likuid=$$v},expression:"total_aset_likuid"}})]},proxy:true}])}),_c('b-table',{attrs:{"small":"","bordered":"","fields":_vm.aset_investasi_fields,"items":_vm.aset_investasi_items,"foot-clone":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
              })})})}},{key:"cell(nilai_saat_ini)",fn:function(row){return [_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right",attrs:{"placeholder":"0"},on:{"keyup":function($event){return _vm.sum('aset_investasi')}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]}},{key:"foot(aset_investasi)",fn:function(){return [_vm._v(" Total ")]},proxy:true},{key:"foot(nilai_saat_ini)",fn:function(){return [_c('b-form-input',{staticClass:"text-right",attrs:{"placeholder":"0","disabled":""},model:{value:(_vm.total_aset_investasi),callback:function ($$v) {_vm.total_aset_investasi=$$v},expression:"total_aset_investasi"}})]},proxy:true}])}),_c('b-table',{attrs:{"small":"","bordered":"","fields":_vm.aset_pribadi_fields,"items":_vm.aset_pribadi_items,"foot-clone":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
              })})})}},{key:"cell(nilai_saat_ini)",fn:function(row){return [_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right",attrs:{"placeholder":"0"},on:{"keyup":function($event){return _vm.sum('aset_pribadi')}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]}},{key:"foot(aset_pribadi)",fn:function(){return [_vm._v(" Total ")]},proxy:true},{key:"foot(nilai_saat_ini)",fn:function(){return [_c('b-form-input',{staticClass:"text-right",attrs:{"placeholder":"0","disabled":""},model:{value:(_vm.total_aset_pribadi),callback:function ($$v) {_vm.total_aset_pribadi=$$v},expression:"total_aset_pribadi"}})]},proxy:true}])}),_c('b-table-simple',{attrs:{"small":"","bordered":""}},[_c('b-tr',[_c('b-th',{staticStyle:{"width":"180px"}},[_vm._v("Total Aset")]),_c('b-th',{staticStyle:{"width":"200px"}},[_c('b-form-input',{staticClass:"text-right",attrs:{"value":"0","disabled":""},model:{value:(_vm.total_aset),callback:function ($$v) {_vm.total_aset=$$v},expression:"total_aset"}})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('h5',[_vm._v("UTANG")]),_c('b-table',{attrs:{"small":"","bordered":"","fields":_vm.utang_jangka_pendek_fields,"items":_vm.utang_jangka_pendek_items,"foot-clone":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
              })})})}},{key:"cell(nilai_saat_ini)",fn:function(row){return [_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right",attrs:{"placeholder":"0"},on:{"keyup":function($event){return _vm.sum('utang_jangka_pendek')}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]}},{key:"foot(utang_jangka_pendek)",fn:function(){return [_vm._v(" Total ")]},proxy:true},{key:"foot(nilai_saat_ini)",fn:function(){return [_c('b-form-input',{staticClass:"text-right",attrs:{"placeholder":"0","disabled":""},model:{value:(_vm.total_utang_jangka_pendek),callback:function ($$v) {_vm.total_utang_jangka_pendek=$$v},expression:"total_utang_jangka_pendek"}})]},proxy:true}])}),_c('b-table',{attrs:{"small":"","bordered":"","fields":_vm.utang_jangka_panjang_fields,"items":_vm.utang_jangka_panjang_items,"foot-clone":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
              })})})}},{key:"cell(nilai_saat_ini)",fn:function(row){return [_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right",attrs:{"placeholder":"0"},on:{"keyup":function($event){return _vm.sum('utang_jangka_panjang')}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]}},{key:"foot(utang_jangka_panjang)",fn:function(){return [_vm._v(" Total ")]},proxy:true},{key:"foot(nilai_saat_ini)",fn:function(){return [_c('b-form-input',{staticClass:"text-right",attrs:{"placeholder":"0","disabled":""},model:{value:(_vm.total_utang_jangka_panjang),callback:function ($$v) {_vm.total_utang_jangka_panjang=$$v},expression:"total_utang_jangka_panjang"}})]},proxy:true}])}),_c('b-table-simple',{attrs:{"small":"","bordered":""}},[_c('b-tr',[_c('b-th',{staticStyle:{"width":"180px"}},[_vm._v("Total Utang")]),_c('b-th',{staticStyle:{"width":"200px"}},[_c('b-form-input',{staticClass:"text-right",attrs:{"value":"0","disabled":""},model:{value:(_vm.total_utang),callback:function ($$v) {_vm.total_utang=$$v},expression:"total_utang"}})],1)],1)],1),_c('table',{staticClass:"table table-bordered table-sm"},[_c('tr',[_c('th',{staticStyle:{"width":"180px"}},[_vm._v("Kekayaan Bersih")]),_c('th',{staticStyle:{"width":"200px"}},[_c('b-form-input',{staticClass:"text-right",attrs:{"value":_vm.kekayaanBersih,"disabled":""}})],1)])])],1)],1),_c('hr'),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"sm":"6"}},[_c('h5',[_vm._v("Pendapatan Bulanan")]),_c('b-table',{attrs:{"small":"","bordered":"","items":_vm.pendapatan_bulanan_items,"thead-class":"d-none"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
              })})})}},{key:"cell(nilai_saat_ini)",fn:function(row){return [(row.index == 4)?[_c('b-form-input',{staticClass:"text-right",attrs:{"value":"0","disabled":""},model:{value:(_vm.total_pendapatan_bulanan),callback:function ($$v) {_vm.total_pendapatan_bulanan=$$v},expression:"total_pendapatan_bulanan"}})]:(row.index == 5)?[_c('b-form-input',{staticClass:"text-right",attrs:{"value":_vm.totalPendapatanTahunan,"disabled":""}})]:[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right",attrs:{"placeholder":"0"},on:{"keyup":function($event){return _vm.sum('pendapatan_bulanan')}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('h5',[_vm._v("Pengeluaran Bulanan")]),_c('b-table',{attrs:{"responsive":"","small":"","bordered":"","items":_vm.pengeluaran_bulanan_items,"foot-clone":"","thead-class":"d-none"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                width: field.key === 'nilai_saat_ini' ? '200px' : '180px',
              })})})}},{key:"cell(nilai_saat_ini)",fn:function(row){return [(row.item.keterangan == 'Investasi rutin')?[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right investasi_rutin",attrs:{"placeholder":"0"},on:{"keyup":function($event){_vm.sum('pengeluaran_bulanan')
                  _vm.calculateTabungan()}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]:(row.item.keterangan == 'Cicilan utang')?[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right cicilan_utang",attrs:{"placeholder":"0"},on:{"keyup":function($event){_vm.sum('pengeluaran_bulanan')
                  _vm.calculateCicilanUtang()}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]:[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency"}],staticClass:"text-right",attrs:{"placeholder":"0"},on:{"keyup":function($event){return _vm.sum('pengeluaran_bulanan')}},model:{value:(row.item.nilai_saat_ini),callback:function ($$v) {_vm.$set(row.item, "nilai_saat_ini", $$v)},expression:"row.item.nilai_saat_ini"}})]]}},{key:"foot(keterangan_utama)",fn:function(){return [_vm._v(" ")]},proxy:true},{key:"foot(keterangan)",fn:function(){return [_vm._v("Total")]},proxy:true},{key:"foot(nilai_saat_ini)",fn:function(){return [_c('b-form-input',{staticClass:"text-right",attrs:{"value":"0","disabled":""},model:{value:(_vm.total_pengeluaran_bulanan),callback:function ($$v) {_vm.total_pengeluaran_bulanan=$$v},expression:"total_pengeluaran_bulanan"}})]},proxy:true}])})],1)],1),_c('b-row',[_c('b-col',[_c('h5',[_vm._v("Rasio Keuangan")]),_c('b-table-simple',{attrs:{"small":"","bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"colspan":"2"}}),_c('b-th',[_vm._v("Ideal")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',{staticStyle:{"width":"40%"}},[_vm._v("Dana Darurat")]),_c('b-td',{staticClass:"text-right",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s((_vm.danaDarurat + "x"))+" ")]),_c('b-td',[_vm._v(_vm._s(_vm.danaDaruratIdeal))])],1),_c('b-tr',[_c('b-td',[_vm._v("Tabungan")]),_c('b-td',{staticClass:"text-right"},[_vm._v(_vm._s(((this.tabungan) + "%")))]),_c('b-td',[_vm._v(_vm._s(_vm.tabunganIdeal))])],1),_c('b-tr',[_c('b-td',[_vm._v("Cicilan utang")]),_c('b-td',{staticClass:"text-right"},[_vm._v(_vm._s(((this.cicilanUtang) + "%")))]),_c('b-td',[_vm._v(_vm._s(_vm.cicilanUtangIdeal))])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }